import React, { useState, useEffect } from 'react';
import './VisitorWaitingForHost.css';
import { VisitorWaitingForHosts,ThankYouScreen, Screens } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl"
import setTimeOutForScreens from "../../Utilities/SetTimeOutforScreen"
import { t } from '../../Translation/Translation';
import Logo from "../Logo/logo";

const VisitorWaitingForHost = props => {
  console.log("in VisitorWaitingForHost");
  const [hostname, setHostname] = useState();
  const[nextButton, setNextButton] = useState(t("NEXT"));
  const[nextButtonStyle, setNextButtonStyle] = useState({});
  const [description, setDescription] = useState();
  const [wifipassword, setWifipassword] = useState();
  const history = useHistory()
  const [qrcode2, setQrcode2] = useState();
  const params = useParams()


  var page = "thankU"
  var response = GetRedirectionUrl(page)



  //To return HTML from string containing HTML tags
  function createMarkup(data) {
    return { __html: data };
  }
  var fromPage = '';

  if(localStorage.getItem("screen_key") && localStorage.getItem("screen_key") !== null) 
  {
    fromPage = JSON.parse(localStorage.getItem("screen_key"));
  }
  // if (params.page) {
  //    fromPage = params.from;
  // }
  useEffect(() => {
    var timer = setTimeOutForScreens(response)
    if(timer !== ''){
      setNextButton(t("HOME"))
      setNextButtonStyle({
        'background': '#D20C14'
      })
     
    }
    return () => clearTimeout(timer);
  }, []);

  /**
   * fetch the qrcode for wifi from backend and show in this page.
   */
  useEffect(() => {
    console.log("inside thank you");
    if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
      var location = JSON.parse(localStorage.getItem("location"))
      let locationId = location['value']
      let lang = (localStorage.getItem('activeLanguage')) ? (localStorage.getItem('activeLanguage')) : 'en'

      let next_screen = [];
      let next = null;
      let screen_type = null;
      if (localStorage.getItem("next_screen") && localStorage.getItem("next_screen") !== null) {
          next_screen = JSON.parse(localStorage.getItem("next_screen"))
      }
      if (localStorage.getItem("next") && localStorage.getItem("next") !== null) {
          next = JSON.parse(localStorage.getItem("next"))
      }
      if (localStorage.getItem("screen_type") && localStorage.getItem("screen_type") !== null) {
          screen_type = JSON.parse(localStorage.getItem("screen_type"))
      }
      console.log(next);
      // let data = {
      //   'locationId': locationId,
      //   'entryId': params.id,
      //   'language': lang
      // }

      let data = {
        'locationId': locationId,
        'type_id': params.type_id,
        'language': lang,
        'screen_id' : params.screen_id,
        'next' : next,
        'all_screens':next_screen,
        'screen_type' : screen_type
      }

      if (localStorage.getItem("hostname") && localStorage.getItem("hostname") !== null) {
        var hostname = JSON.parse(localStorage.getItem("hostname"));
        setHostname(hostname)
      }
      APICALL.service(Screens, 'POST', data)
        .then((result) => {
          if (result) {
            console.log(result.qrcode)
            setQrcode2(result.qrcode2)
            var des = result.description
            if(hostname !== undefined && hostname !== null && hostname !== '' && (fromPage === 'withAppointment' || fromPage === 'interview') ){
              hostname = hostname.charAt(0).toUpperCase() + hostname.slice(1);
              hostname = ', ' + hostname + ','
              des =  des.replace("!hostname", hostname);
            }else{
              des =  des.replace("!hostname", '');
            }
            setDescription(des)
            setWifipassword(result.qrcode)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      history.push({
        pathname: "/select-location"

      });
    }


  }, []);
  var item_css = '';
  if(fromPage === 'common'){
     item_css = {
   'align-items': 'flex-start'
    }
 }
 else{
    item_css = {
   'align-items': 'center'
   }
 }
 var interview_qrcode = ''
 if(fromPage === 'interview'){
  interview_qrcode = {
   'width': '400px'
 }
 }else{
  interview_qrcode = {
    'width': '420px'
  }
 }
  return (
    <div className="col-md-12 col-lg-12 col-sm-12 thank_main p-0">
      <Logo />

      <div className="thank_body row m-0 p-0 with_out4">
      {
          /*fromPage === 'common' ?     
          <div className="col-md-12 header_main pb-4">
          <h3 className="head_left mb-0">
              <label className="mb-0"><p className="thanku_head2">{t('Thank you')} </p> </label>{" "}
            </h3>
            </div>

            :
	    */
            // normal flow
            <div className="word-break  Richtexterditor col-md-6 col-lg-6 col-sm-6 m-auto  float_left">

              <div className="col-md-12">
                  <h3 className="head_left mb-0">
                    {" "}
                    <label className="mb-0">
                      <p className="thanku_head2 pb-3"> {t('Thank you')}</p>
                      {
                       /* fromPage === 'withAppointment' ? <div  dangerouslySetInnerHTML={createMarkup(description)} /> :
                        <label>
                          {
                            fromPage === 'interview'? <p className="thanku_head3 pb-3"> {t('Your HR')}, <b>  { hostname }</b>, {t('has been informed about your arrival.')}</p> :
                            <p className="thanku_head3 pb-3"> {t('One of the receptionist has been informed about your arrival.')}</p>
                          }
                          </label>
			  */
			  <div  dangerouslySetInnerHTML={createMarkup(description)} />
                        
                      }
                    </label>{" "}
                </h3>
              </div>
            </div>
        }
        <div className="col-md-6 m-auto col-lg-6 col-sm-6 qrtowifi " style={item_css}>
          {/* <p className="wifi_desc">{wifidescription}</p> */}
          {fromPage === 'interview' ?
          <div className="wifi_sec " > 
          <img className=" crop" style={interview_qrcode} alt="wifi qrcode" src={qrcode2} />
          <h3>{t('Watch me')}</h3>
          </div>
          :''
          }

          <div className="wifi_sec qrcode1">
          <img className=" crop" style={interview_qrcode} alt="wifi qrcode" src={wifipassword} />
          <h3>{t('WiFi access')}</h3>
          </div>
          
        </div>

      </div>
      <div className="row m-0 p-0 mt-3">
        <div className="col-md-6 col-lg-6 p-0 text-left">
        {response['back'] !== undefined && response['back'] !== null ?
            (<button className="accept_button mr-10"
              onClick={() => {
                history.push({
                  pathname: response['back'],
                });
              }}
            >
              {t('Back')}
            </button>) : null
          }
        </div>
        <div className="col-md-6 col-lg-6 p-0 text-right">
          {response.next !== undefined && response.next !== null ?
            (<button className="sub_button" style={nextButtonStyle}
              onClick={() => {
                history.push({
                  pathname: response.next
                });
              }}
            >
              {nextButton}
            </button>) : null
          }
        </div>
      </div>

    </div>
  );
};


export default VisitorWaitingForHost;
