import React, { useState } from 'react';
import './ScanQrAndEmail.css';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckAppointmentsAppUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import Header from "../Header/header";
import Logo from "../Logo/logo";
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl";
import { useParams } from "react-router";
import { t } from '../../Translation/Translation';
import qrcode from "../../Static/images/QR-Latest_Gif.gif";
import BarcodeReader from 'react-barcode-reader'
import Popup from "../../Utilities/Popup/modelPopup";



const ScanQrAndEmail = props => {
  const params = useParams()
  const history = useHistory()
  const location = useLocation();
  const pathRediect = location.pathname;
  const [email, setEmail] = useState('');
  const [errormessage, setErrormessage] = useState();
  const [displayError, setDisplayError] = useState();
  const [popup, setPopup] = useState('');
  const [body, setBody] = useState('');
  const[nextButtonStyle] = useState({'background': '#D20C14'});

  var header = (pathRediect === '/checkout') ? 'check-out' : 'check-in'
  var checkstatus = (pathRediect === '/checkout') ? 'check-out' : 'check-in'
  var response = {}
  var page = []
  var heading = `Visitor ${header}`
  var screen_key = '';
  if (localStorage.getItem("screen_key") && localStorage.getItem("screen_key") !== null) {
     screen_key = JSON.parse(localStorage.getItem("screen_key"));
  }

  if (screen_key === 'withAppointment') {
    page = "emailAndScan"
    response = GetRedirectionUrl(page)
  }else if(screen_key === 'interview'){
    page = "interview"
    checkstatus =  'interview'
    response = GetRedirectionUrl(page)
  } else {
        response = {}
        response.back = '/'
  }
  var final_location = '';
  if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
    let location2 = JSON.parse(localStorage.getItem("location"));
    final_location = location2;
  }

  /**
   * this function will close the error message popup
   */
  const closeAlertPopup = () => {
    setPopup("");
    setBody('');
  }

  const handleErrorWebCam = (error) => {
    console.log(error);
  }

  /**
   * this function will scan the qr code and send the scanned data to the backend
   * @param {*} result -->qr code data
   */
  const handleScanWebCam = (result) => {
    if (result) {
      // check if user is check in status 
      // API call with email to check status
        let data1 = {
          'scan': result,
          'verify': 'scan',
          'checkstatus': checkstatus,
          'location': final_location
        }
        appointmentDetails(data1, 'scan')
    }
  }

  /**
   * This function is used for both checkin and checkout purpose
   * @param {*} data1 --> qr code data or email
   * @param {*} error -->type scan or email
   */
  const appointmentDetails = (data1, error) => {
    APICALL.service(CheckAppointmentsAppUrl, "POST", data1)
      .then((result) => {
        console.log(result);
        console.log(result.action);
        if(result.host_name !== undefined && result.host_name !== null){
          localStorage.setItem("hostname", JSON.stringify(result.host_name));
        }
        if (result.action) {
          console.log("innnn");
          setDisplayError(error)
          if(result.action === 'interview'){
           let popupmsg = t('Please checkin through I have an interview')
           setPopup(popupmsg)
          }else if(result.action === 'appointment'){
            let popupmsg = t('Please checkin through I have an appointment')
           setPopup(popupmsg)
          }
          else if (result.action === 'checkin') {
            console.log("checkin 106");
            console.log(response.next);
            history.push({
              pathname: response.next
            });
          }
          else if(result.action === 'popup'){
            let popupmsg = ''
            if(result.popupType === 0){
            popupmsg = t("We notice that your meeting starts at")+" "+ result.appointmentTime['start'] 
            setBody(t("You can use the QR code as of")+" "+ result.appointmentTime['scan'])
            }else{
              popupmsg = t("Your Qr code has expired");
            }
            setPopup(popupmsg)
          }
          else if (result.action === 'checkout') {
            history.push({
              pathname: '/checkout-details',
            });
          } else if (result.action === 'pleasecheckin') {
            setErrormessage('Please check-in before check-out')

          }
          else if (result.action === 'alreadycheckin') {
            if(result.popupType === 'checkout'){
               setPopup(t('Please logout your previous appointment'))
              }else{
               setPopup(t('You have already registered'))
              }
           
          } else {
            if (error === 'scan') {
              setErrormessage('Please scan valid QR-code')
            } else {
              setErrormessage('No appointments for this Email')
            }
          }
        }
      })
  }
  const onSubmit = data => {
    // var regEmail = /^([a-zA-Z0-9_\-.+]+@([a-zA-Z-]+[.]{1}[a-zA-Z]+))$/  
    var regEmail = /^([a-zA-Z0-9_\-\.\+]+\@([a-zA-Z0-9-]+[\.]{1}[a-zA-Z]+))$/  
    if (data) {
      if(regEmail.test(data) === false){
        setErrormessage('Please enter valid Email')
      }else{
        let data1 = {
          'email': data,
          'verify': 'email',
          'checkstatus': checkstatus,
          'location': final_location
        }
        appointmentDetails(data1, 'email')
      }
    } else {
      setDisplayError('email')
      setErrormessage('Please enter Email')
    }
  };
  const submitEmail = e => {
    var data = e.target.value
    setEmail(data)
    if(data){
      // var regEmail = /^([a-zA-Z0-9_\-.+]+@([a-zA-Z-]+[.]{1}[a-zA-Z]+))$/
      var regEmail = /^([a-zA-Z0-9_\-\.\+]+\@([a-zA-Z0-9-]+[\.]{1}[a-zA-Z]+))$/
        if(regEmail.test(data) === false){
          setErrormessage('Please enter valid Email')
        }else{
          setErrormessage('')

        }
    }
  }
  return (
    <div>
      
<Logo />

      <div className="row mr-0 ml-0 with_out display_inherit">
      <Header heading={t(heading)} />
      <Popup
        show={popup !== "" ? true : false}
        onHide={closeAlertPopup}
        size={"lg"}
        title={"Alert"}
        body={popup}
        body2={body}
      />
      <div className="col-lg-12 col-md-12 row m-0 pt-5">
        <div className="col-4 vertical-middle pt-5 qrcode_height_sec">
          <h5 className="show_qr pb-3 scan-error-msg">{t('Show me QR code')}</h5>
          <div className="">
          <img className="qr_sec_img" alt="machine" src={qrcode}></img>
          </div>
        
          <BarcodeReader
          onError={handleErrorWebCam}
          onScan={handleScanWebCam} 
          />
          {errormessage && displayError === 'scan' ?
            <div className='error-message2 scan-error-msg'>
              {t(errormessage)}
            </div> : ''
          }
         
        </div>
      
            
          
        
        <div className="col-2 mr-0 ml-0  vertical-middle">
          <span className='or'>{t('ou alors')}</span>
        </div>
        <div className="col-5 vertical-middle">
            <label className="textcolor1 col-md-12 lext-left p-0 pb-2 red-col">{t('Insert your email')}</label>
            <input
              type="email"
              name="email"
              className="form-control height_sixty"
              onChange={e => submitEmail(e)}
              value={email}
              id="email"
              autocomplete="off"
              aria-describedby="emailHelp"
            />

            {errormessage && displayError === 'email' ?
              <div className='error-message'>
                {t(errormessage)}
              </div> : ''
            }
            <div className="col-md-12 m-0 pt-4 pl-0 pr-0 text-left"> <button type="submit" className="sub_button nxt_btn mb-2" onClick={()=>onSubmit(email)}> {t('NEXT')} </button>

            </div>
        </div>
        <div className='error-message2'>
              <p className="mb-0">{t('Tip: Please keep the brightness of your phone to maximum before scanning')}.</p>
          </div>
        </div>



      </div>
      <div className="text-left pt-3">
        <button className="accept_button   mt-1" style={nextButtonStyle}
            onClick={() => {
              history.push({
                pathname: '/'
              });
            }}
          >
            {t('HOME')}
          </button>
      </div>
    </div>
  );
};
export default ScanQrAndEmail;