import React from 'react'
import loadingGif from "../Static/images/Spin.gif";
const LoadingIcon = props => {
    return (
        <div className="loading" id="loading-icon"  style={{ display: "none" }}> 
        <img src={loadingGif} width="10%" />
        </div>
    )
}



export default LoadingIcon