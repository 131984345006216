import React from "react";

const Test22 = () => {
return(
    <div>
        <div className="col-md-12 col-lg-12 col-sm-12 pt-4 text-left">
    <h3>Forbidden </h3>
    <p>You dont have permission to access this resource.</p>
    </div>
    </div>
);
}

export default Test22;