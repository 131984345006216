export default function GetRedirectionUrlHome(page) {
    console.log("GetRedirectionUrlHome");
    console.log(page);
    console.log("In 5");
    var home_screen = [];
    var next = '';
    console.log("In 29333");
    if (localStorage.getItem("home_screen") && localStorage.getItem("home_screen") !== null) {
        home_screen = JSON.parse(localStorage.getItem("home_screen"))
    }
    console.log(home_screen.home_screen);
    let button = {}
   
    if(home_screen.home_screen != null && home_screen.home_screen ==='yes')
    {
        console.log(home_screen);
        console.log("yes in 41");
        let screen_id = home_screen.screen_id
        let type_id = home_screen.type_id
        let response = geturl(page, screen_id, type_id, next);
        button.next = response
        if (page === 'emailAndScan' || page === 'visitorDetails' || page === 'interview') {
            
            button['back'] = '/'
        }
        console.log(response);
    }
    else{
        button.next = '/'
    } 
    return button

}

// pathname: route + '/screen/' + screen_id + '/' + type_id,
const geturl = (page, screen_id, type_id, next) => {

    switch (page) {
        case "emailAndScan":
            return '/common/screen/' + screen_id + '/' + type_id;
        case "interview":
            return '/common/screen/' + screen_id + '/' + type_id;
        default:
            return "/";
    }
}