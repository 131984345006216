import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Header from "../Header/header";
import setTimeOutForScreens from "../../Utilities/SetTimeOutforScreen"
import { t } from '../../Translation/Translation';
import Logo from "../Logo/logo";
const Checkout = props => {
  const history = useHistory()
  const[nextButtonStyle, setNextButtonStyle] = useState({});
  // const [visitortype, setVisitorType] = useState();
  const [visitortitle] = useState('Thank you');
  useEffect(() => {
    let response = []
    setNextButtonStyle({
      'background': '#D20C14'
    })
    var timer = setTimeOutForScreens(response)
    return () => clearTimeout(timer);
  }, []);


  return (
    <div>
      
    <Logo />

    <div className="col-md-12 col-lg-12 col-sm-12 with_out">
    <Header heading={t(visitortitle)} />
      
    <div className="word-break pt-5 Richtexterditor col-md-6 m-auto" >
    <p>{t("Have a nice day")}</p>
    </div>

     
    </div>
    <div className="col-md-12 col-lg-12 col-sm-12 mb-3 mt-3 p-0 row m-0 ">
    <div className="col-lg-6 col-md-6 col-sm-6 text-left p-0">
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6  p-0 text-right">
            <button className="accept_button" style={nextButtonStyle}
              onClick={() => {
                history.push({
                  pathname: '/',
                });
              }}
            >
              {t("HOME")}
            </button>
        </div>

    </div>
  </div>

  );
};

export default Checkout;