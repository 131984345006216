import React, { useState, useEffect } from 'react';
import { APICALL } from "../../Services/ApiServices";
import { VisitorTypeUrl } from "../../Services/ApiEndPoints";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import Header from "../Header/header";
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl"
import setTimeOutForScreens from "../../Utilities/SetTimeOutforScreen"
import { t } from '../../Translation/Translation';
import Logo from "../Logo/logo";


const NewVisitorType = props => {
  let params = useParams();
  const history = useHistory()
  const [visitortype, setVisitorType] = useState();
  const [visitortitle, setVisitortitle] = useState('new screen');
  const[nextButton, setNextButton] = useState(t("NEXT"));
  const[nextButtonStyle, setNextButtonStyle] = useState({});
  var page = "newScreen"
  var response = GetRedirectionUrl(page)
  console.log(response)
  function createMarkup(data) {
    return { __html: data };
  }
  useEffect(() => {
    var timer = setTimeOutForScreens(response)
    if(timer !== ''){
      setNextButton(t("HOME"))
      setNextButtonStyle({
        'background': '#D20C14'
      })
     
    }
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {

    if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
      var location = JSON.parse(localStorage.getItem("location"))
      let locationId = location['value']
      let entryId = Number(params.id)
      let lang = (localStorage.getItem('activeLanguage'))? (localStorage.getItem('activeLanguage')): 'en'
      let data = {
        'locationId': locationId,
        'type': 'newscreen',
        'entryId': entryId,
        'language': lang

      }

      APICALL.service(VisitorTypeUrl, "POST", data)
        .then((result) => {
          if (result) {
            let title = result['DATA']['title'];
            setVisitorType(result['DATA']['visitorType'])
            setVisitortitle(title)
           
          }
        })

    } else {
      history.push({
        pathname: "/select-location"

      });
    }
  }, [params.id, params.page]);
  return (
    <div>
      
    <Logo />

    <div className="col-md-12 col-lg-12 col-sm-12 with_out">
    <Header heading={t(visitortitle)} />
      
    <div className="word-break pt-5 Richtexterditor col-md-6 m-auto" dangerouslySetInnerHTML={createMarkup(visitortype)} />

     
    </div>
    <div className="col-md-12 col-lg-12 col-sm-12 mb-3 mt-3 p-0 row m-0 ">
      <div className="col-lg-6 col-md-6 col-sm-6 text-left p-0">
      {response['back'] !== undefined && response['back'] !== null ?
            (<button className="accept_button mr-10 back-home-button"
              onClick={() => {
                history.push({
                  pathname: response['back'],
                });
              }}
            >
              {t('Home')}
            </button>) : null
          }
      </div>
      <div className="col-lg-6 col-md-6 col-sm-6  p-0 text-right">
        {response.next !== undefined && response.next !== null ?
          (<button className="accept_button" style={nextButtonStyle}
            onClick={() => {
              history.push({
                pathname: response.next,
              });
            }}
          >
            {nextButton}
          </button>) : null
        }
      </div>
    </div>
  </div>
  );
};

NewVisitorType.propTypes = {

};

export default NewVisitorType;