import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videoUrl }) => {
    return (
        <ReactPlayer
            url={videoUrl}
            controls // Show video controls (play, pause, etc.)
            // width="auto"
            // height="auto"
            playing={true} // Enable auto play
        />
    );
};

export default VideoPlayer;