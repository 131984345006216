export default function setTimeOutForScreen(response){
  if (response.next === undefined || response.next === null || response.next === '/') {
    if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
    let location = JSON.parse(localStorage.getItem("location")) 
    let time = (location['screentime'] !== null) ? location['screentime'] : 20
    let Ttime = time * 1000
    var setTime = setTimeout(function(){ window.location.href = '/' }, Ttime);
    return setTime
    }
    else {
      window.location.href = "/select-location"
      }
    
   }
    else {
            return ''
      }
}