import React from 'react';
import "../SelectVisitor/SelectVisitor.css";

const Header = props => {
  // console.log(props);
  return (
      <div className="col-md-12 header_main pb-4">
      <h3 className="head_left mb-0">
          {/* {" "} */}
          <label className="mb-0"><b className="four_six"> {props.heading}</b> </label>{" "}
        </h3>
        </div>
  );
};

export default Header;