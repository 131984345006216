
import React, { useState, useEffect } from 'react';
import './Department.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { useHistory} from 'react-router-dom';
import { DepartmentUrl, WithoutAppUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
const Department = props => {
    // const history = useHistory();
    const [department, setDepartments] = useState([]);

    useEffect(() => {
        APICALL.service(DepartmentUrl, "GET")
            .then((result) => {
                setDepartments(result['DATA']);
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    const handleChange = data => {

        let data2 = {
            type: 'department',
            department_type: data,
            unique_key: props.location.state.details.unique_key,
        }

        APICALL.service(WithoutAppUrl, "POST", data2)
            .then((result) => {
                return;
            })
            .catch((error) => {
                console.error(error);
            });

    };

    // const priviousPage = data => {
    //     if(props.location.state != undefined){
    //         history.push({
    //             pathname: '/without-appointment',
    //             state: {unique_key:props.location.state.details.unique_key}
    //           })
    //     }
    //     else{
    //         history.push({
    //             pathname: '/without-appointment',
    //           })
    //     }

    // }



    return (
        <Container className="bgcolor m-0 col-lg-12">
            <Row>
                <Col></Col>
                <Col className="headstyle"> <h5>Please select the department</h5></Col>
                <Col></Col>
            </Row>

            <Row>
                <Col>
                </Col>
                <Col xs={6} className="fullbox">

                    {
                        department.map(department_name => (
                            <Row className="eachbox">
                                <Col lg="6">
                                    <Button className="sub_button" onClick={() => handleChange(department_name)} variant="primary" size="lg" active>
                                        {department_name}
                                    </Button>
                                </Col>
                                <Col lg="6">

                                </Col>
                            </Row>
                        ))
                    }


                    {/* <Row className="eachbox">
                <Col lg="6"><Button className="sub_button" variant="danger" onClick={() => priviousPage()} size="lg" active>
                                    Back
                </Button>
                    </Col>
                    <Col lg="6">
                    </Col>  
                </Row> */}


                </Col>
                <Col></Col>
            </Row>


            {/* </form> */}
        </Container>
    )
}

export default Department