
import React, { useState, useEffect } from 'react';
import './Delivery.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from "react-router";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import { DeliveryUrl, ContractorUrl ,Screens} from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import PDFReader from "../../Utilities/PdfReader/PdfReader";
import VideoPlayer from "../../Utilities/VideoPlayer/VideoPlayer";
import Header from "../Header/header";
import Logo from "../Logo/logo";
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl"
import { t } from '../../Translation/Translation';
import setTimeOutForScreens from "../../Utilities/SetTimeOutforScreen"

const Delivery = ({nextFunction}) => {
    console.log("in delivery");
    const history = useHistory();
    const[nextButton, setNextButton] = useState(t("NEXT"));
    const[nextButtonStyle, setNextButtonStyle] = useState({});
    let params = useParams();
    const [message, setMessage] = useState("")
    const [description, setDescription] = useState("")
    const [imgUrl, setUrl] = useState("")
    const [fileType, setFileType] = useState("")

    if (params.page !== undefined) {
        var from_url = params.from
    }

    function createMarkup(data) {
        return { __html: data };
    }

    var page = "common"
    // var response = GetRedirectionUrl(page)
    /**
     * This function is used to go to next page when click on next button
     */
    // const nextFunction = () => {
    //     console.log(response.next);
    //     history.push({
    //         pathname: response.next
    //     });
    // }

    /**
     * This function is used to fetch and show the pdf or image for contractor or delivery person
     */
    useEffect(() => {
        fetchdata();    
    }, []);
    const fetchdata = () => {
        let next_screen = [];
        let next = null;
        let screen_type = null;

        var location = JSON.parse(localStorage.getItem("location"))
        let lang = (localStorage.getItem('activeLanguage'))? (localStorage.getItem('activeLanguage')): 'en'
        let locationId = location['value']

        if (localStorage.getItem("next_screen") && localStorage.getItem("next_screen") !== null) {
            next_screen = JSON.parse(localStorage.getItem("next_screen"))
        }
        if (localStorage.getItem("next") && localStorage.getItem("next") !== null) {
            next = JSON.parse(localStorage.getItem("next"))
        }
        if (localStorage.getItem("screen_type") && localStorage.getItem("screen_type") !== null) {
            screen_type = JSON.parse(localStorage.getItem("screen_type"))
        }
        console.log(next);
        let data = {};
        if (next !== undefined && next != null)
        {
            console.log('del if');

             data = {
                'locationId': locationId,
                'type_id': params.type_id,
                'language': lang,
                'screen_id' : params.screen_id,
                'next' : next,
                'all_screens':next_screen,
                'screen_type' : screen_type
              }

              APICALL.service(Screens, "POST",data)
                .then((result) => {
                    setMessage(result.DATA['title']);
                    setUrl(result.DATA['img_url']);
                    setDescription(result.DATA['description']);
                    localStorage.setItem("next", JSON.stringify(result.DATA['next']));
                    localStorage.setItem("prev", JSON.stringify(result.DATA['prev']));
                    localStorage.setItem("screen_type", JSON.stringify(result.DATA['screen_type']));

                    var file_type = (APICALL.get_url_extension(result.DATA['img_url']));
                    if (file_type === "pdf") {
                        setFileType("pdf");
                    }else if (file_type === "mp4" || file_type === "mpeg" || file_type === "avi") {
                        setFileType("video");
                    } else {
                        setFileType("image");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else{
            console.log('del else');
            data = {
                'locationId': locationId,
                'type_id': params.type_id,
                'language': lang,
                'screen_id' : params.screen_id
              }

              APICALL.service(Screens, "POST",data)
                .then((result) => {
                    setMessage(result.DATA['title']);
                    setUrl(result.DATA['img_url']);
                    setDescription(result.DATA['description']);
                    localStorage.setItem("next", JSON.stringify(result.DATA['next']));
                    localStorage.setItem("prev", JSON.stringify(result.DATA['prev']));
                    localStorage.setItem("screen_type", JSON.stringify(result.DATA['screen_type']));
                    localStorage.setItem("next_screen", JSON.stringify(result.SCREENS));
                    
                    
                    var file_type = (APICALL.get_url_extension(result.DATA['img_url']));
                    if (file_type === "pdf") {
                        setFileType("pdf");
                    }else if (file_type === "mp4" || file_type === "mpeg" || file_type === "avi") {
                        setFileType("video");
                    } else {
                        setFileType("image");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
       
        
    }
const nextFunctionLoad = () => {
    let screen_type = null;
    if (localStorage.getItem("screen_type") && localStorage.getItem("screen_type") !== null) {
        screen_type = JSON.parse(localStorage.getItem("screen_type"))
    }
    if(screen_type !== undefined && screen_type != null && screen_type == 'ThankYou')
    {
        console.log("140");
        let screen_id = params.screen_id
        let type_id = params.type_id

        history.push({
            pathname: '/thanks/screen/' + screen_id + '/' + type_id,
        });
    }
    else{
        console.log('inside')
        fetchdata();
    }
}

    var response = GetRedirectionUrl(page)
    // var response = null

    var heading = (from_url === 'delivery') ? 'Delivery' : 'Contractor';

    return (
        <div>
            <Logo />

            <Container className="bgcolor m-0 col-lg-12">
           
                <Row>
                    
                </Row>

                <Row className="with_out3">
                {/* <Header heading={t(message)} /> */}
                   
                    <Col lg={11} className="fullbox m-auto pt-3">

                        <Row className="eachbox del_sec_row">
                            {fileType === "pdf" && imgUrl && (
                                <PDFReader
                                    pdf={imgUrl}
                                />
                            )}

                            {fileType === "video" && imgUrl && (
                                <VideoPlayer
                                    videoUrl={imgUrl} // Replace with the actual prop name for your VideoPlayer component
                                />
                            )}

                            {fileType === "image" && imgUrl && (
                                <img className="for_img" src={imgUrl} alt="route" />
                            )}
                        </Row>
			
                        <Row className="eachbox">
                           <p className="col-md-12 page_nation pt-3" dangerouslySetInnerHTML={createMarkup(description)} />
                            {/* <p className="col-md-12 page_nation pt-3">{description}</p> */}
                        </Row>

                    </Col>

                </Row>
                <Row className="eachbox mb-4">
                    <div className="col-lg-6 col-md-6 col-sm-6 text-left p-0">
                    {response['back'] !== undefined && response['back'] !== null ?
                        (<button className="accept_button mr-10 back-home-button"
                        onClick={() => {
                            history.push({
                            pathname: response['back'],
                            });
                        }}
                        >
                        {t('Home')}
                        </button>) : null
                    }
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 text-right p-0">
                        {response.next !== undefined && response.next !== null ?
                            (<button className="sub_button" style={nextButtonStyle}
                                onClick={() => nextFunctionLoad()}
                            >
                                {nextButton}
                        </button>) : null
                        }
                    </div>
                </Row>

            </Container>
        </div>
    )
}


export default Delivery;


