import { useParams } from "react-router";
export default function GetRedirectionUrl(page) {
    console.log("GetRedirectionUrl");
    console.log(page);
    // return;
    var params = useParams()
    var details = [];
    var next = '';
    var prev = '';

    // var location = ''
    if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
        // location = JSON.parse(localStorage.getItem("location"))
    } else {
        window.location.href = "/select-location"

    }
    if (localStorage.getItem("details") && localStorage.getItem("details") !== null) {
        details = JSON.parse(localStorage.getItem("details"))

    } else {
        window.location.href = "/"

    }
    if (localStorage.getItem("next") && localStorage.getItem("next") !== null) {
        next = JSON.parse(localStorage.getItem("next"))
    }
    // if (localStorage.getItem("prev") && localStorage.getItem("prev") !== null) {
    //     prev = JSON.parse(localStorage.getItem("prev"))
    // }

    // var pageNo = Number(params.page)
    // console.log(details);
    // console.log(params);return;
    // return;
    
    // console.log("kkkk");
    // console.log(next_screen.next);
    // var next = next_screen.next
    console.log(next)
    console.log(params)

    var from = (params.from)
    // console.log(pageNo)
    let button = {}
    // if (details != null && details.length > 0 && details[pageNo]['screenType'] === page) {
    if (details != null && details.length > 0) {
        // console.log("kkkk");
        // console.log(next_screen.next);return;
        // console.log("ggggg");return;next
        // if (details[pageNo + 1] !== undefined) {
        // if (next !== undefined && next != null) {
            // let pageUrl = pageNo + 1
            // let fromUrl = from
            let screen_id = params.screen_id
            let type_id = params.type_id
            // let response = geturl(details[pageNo + 1]['screenType'], details[pageNo + 1]['screenDetails'], pageUrl, fromUrl);
            let response = geturl(page, screen_id, type_id, next);
            button.next = response
        // }
        // else{
        //     button.next = '/'
        // } 
        if (page === 'emailAndScan' || page === 'visitorDetails' || page === 'interview') {
            
            button['back'] = '/'
        } 
    }
    else{
        button.next = '/'
    } 
    // else if(details != null && details.length > 0 && prev != null)
    // {
    //     if (prev !== undefined && prev != null) {
    //         let screen_id = params.screen_id
    //         let type_id = params.type_id
    //         let response = geturl(page, screen_id, type_id, next);
    //         button['back'] = response;
    //     }
    // }
    // else {
    //     console.log("eeeeee");
    //     window.location.href = '/'
    // }
    return button

}
/**
 * this function will return the url with respect to the pages.
 * @param {*} pageType 
 * @param {*} entryId 
 * @param {*} pageNo 
 * @param {*} fromUrl 
 */
// const geturl = (pageType, entryId, pageNo, fromUrl) => {
//     switch (pageType) {
//         case 'imageOrDocs':
//             return "/safety-rules/" + pageNo + '/' + fromUrl + '/' + entryId;
//         case "emailAndScan":
//             return '/with-appointment/' + pageNo + '/' + fromUrl + '/' + entryId;
//         case "visitorDetails":
//             return '/without-appointment/' + pageNo + '/' + fromUrl + '/' + entryId;
//         case "contractor":
//             return "/without-appointment/" + pageNo + '/' + fromUrl + '/' + entryId;
//         case "thankU":
//             return "/visitor-waiting-for-host/" + pageNo + '/' + fromUrl + '/' + entryId;
//         case "visitorType":
//             return "/";
//         case "newScreen":
//             return "/extra-screen/" + pageNo + '/' + fromUrl + '/' + entryId;
//         case "delivery":
//             return "/delivery/" + pageNo + '/' + fromUrl + '/' + entryId;
//         case "interview":
//             return "/interview/" + pageNo + '/' + fromUrl + '/' + entryId;
//         default:
//             return "/";
//     }
// }

// pathname: route + '/screen/' + screen_id + '/' + type_id,

const geturl = (page, screen_id, type_id, next) => {
    // console.log(pageType);

    switch (page) {
        // case 'imageOrDocs':
        //     return "/safety-rules/" + pageNo + '/' + fromUrl + '/' + entryId;
        case "emailAndScan":
            return '/common/screen/' + screen_id + '/' + type_id;
        //     return '/with-appointment/' + pageNo + '/' + fromUrl + '/' + entryId;
        case "visitorDetails":
            // return '/without-appointment/' + pageNo + '/' + fromUrl + '/' + entryId;
            return '/common/screen/' + screen_id + '/' + type_id;
        // case "contractor":
        //     return "/without-appointment/" + pageNo + '/' + fromUrl + '/' + entryId;
        // case "thankU":
        //     // return "/visitor-waiting-for-host/" + pageNo + '/' + fromUrl + '/' + entryId;
        //     return '/common/screen/' + screen_id + '/' + type_id;
        // case "visitorType":
        //     return "/";
        // case "newScreen":
        //     return "/extra-screen/" + pageNo + '/' + fromUrl + '/' + entryId;
        case "common":
            // return "/common/" + pageNo + '/' + fromUrl + '/' + entryId;
            // return '/common/screen/' + screen_id + '/' + type_id + '/' + next;
            return '/common/screen/' + screen_id + '/' + type_id;

        case "interview":
            return '/common/screen/' + screen_id + '/' + type_id;
        //     return "/interview/" + pageNo + '/' + fromUrl + '/' + entryId;
        default:
            return "/";
    }
}