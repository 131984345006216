import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './PdfReader.css';
import { t } from '../../Translation/Translation';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfReader = props => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(props.currentPage || 1);
  const pdf = props.pdf;

  useEffect(() => {
    setPageNumber(props.currentPage || 1);
  }, [props.currentPage]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    const newPageNumber = pageNumber + offset;
    if (newPageNumber >= 1 && newPageNumber <= numPages) {
      setPageNumber(newPageNumber);
    }
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const renderNavigationButtons = () => {
    if (numPages > 1) {
      return (
        <div className="page_sec pb-3">
          <p className="page_nation">
            {t('Page')} {pageNumber} {t('of')} {numPages}
          </p>
          <button
            className="pdf_btb"
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            {t('Previous')}
          </button>
          <button
            className="pdf_btb"
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            {t('Next')}
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="pdf-page with_out2 col-lg-12 col-md-12">
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} scale={2}>
        <Page pageNumber={pageNumber} className="pdf-page" scale={3.5} />
      </Document>
      {numPages && renderNavigationButtons()}
    </div>
  );
};

export default PdfReader;
