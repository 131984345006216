import React from "react";
import { Route, Switch, BrowserRouter  } from "react-router-dom";
import SelectVisitor from '../Pages/SelectVisitor/SelectVisitor';
import WithoutAppointment from '../Pages/WithoutAppointment/WithoutAppointment'
import Contractor from '../Pages/Contractor/Contractor'
import Delivery from '../Pages/Delivery/Delivery';
//import ParentDelivery from '../Pages/Delivery/ParentDelivery';

import Department from '../Pages/SelectDepartment/Department';
import Checkout from '../Pages/Checkout/Checkout'
import ScanQrAndEmail from '../Pages/ScanQrAndEmail/ScanQrAndEmail'
import SafetyRules from '../Pages/SafetyRules/SafetyRules'
import VisitorWaithingfForHost from '../Pages/VisitorWaitingForHost/VisitorWaitingForHost'
import SelectLocation from '../Pages/SelectLocation/SelectLocation'
import NewVisitorType from '../Pages/NewVisitorType/NewVisitorType'
import Error from "../Pages/404";

const BaseRouter = (props) => (
    <div>
      <BrowserRouter>
      <Switch>
      {/* route starts here */}
      <Route exact path="/" component={SelectVisitor} />
      {/* <Route exact path="/with-appointment" component={ScanQrAndEmail} />
      <Route exact path="/interview" component={ScanQrAndEmail} />
      <Route exact path="/without-appointment" component={WithoutAppointment} />
      <Route exact path="/visitor-waiting-for-host" component={VisitorWaithingfForHost} />
      <Route exact path="/contractor" component={Contractor} />
      <Route exact path="/delivery" component={Delivery} />
      <Route exact path="/interview/:page/:from/:id" component={ScanQrAndEmail} /> */}

	{/*<Route exact path="/common/screen/:screen_id/:type_id" component={ParentDelivery} />*/}
      <Route exact path="/common/screen/:screen_id/:type_id" component={Delivery} />
      <Route exact path="/thanks/screen/:screen_id/:type_id" component={VisitorWaithingfForHost} />
      <Route exact path="/without-appointment/screen/:screen_id/:type_id" component={WithoutAppointment} />
      <Route exact path="/with-appointment/screen/:screen_id/:type_id" component={ScanQrAndEmail} />
      <Route exact path="/interview/screen/:screen_id/:type_id" component={ScanQrAndEmail} />

      <Route exact path="/with-appointment/:page/:from/:id" component={ScanQrAndEmail} />
      {/* <Route exact path="/without-appointment/:page/:from/:id" component={WithoutAppointment} /> */}
      {/* <Route exact path="/without-appointment/:page/:from" component={WithoutAppointment} /> */}
      {/* <Route exact path="/visitor-waiting-for-host/:page/:from/:id" component={VisitorWaithingfForHost} /> */}
      <Route exact path="/contractor/:page/:from/:id" component={Contractor} />
      {/* <Route exact path="/delivery/:page/:from/:id" component={Delivery} />
      <Route exact path="/delivery/:page/:from/" component={Delivery} />
      <Route exact path="/delivery/:page/" component={Delivery} /> */}
      <Route exact path="/department" component={Department} />
      <Route exact path="/checkout" component={ScanQrAndEmail} />
      <Route exact path="/checkout-details" component={Checkout} />
      <Route exact path="/safety-rules" component={SafetyRules} />
      <Route exact path="/safety-rules/:page/:from/:id" component={SafetyRules} />
      <Route exact path='/select-location' component={SelectLocation} />
      <Route exact path="/visitor-details" component={WithoutAppointment} />
      <Route exact path="/visitor-type-new-screen/:page/:from/:id" component={NewVisitorType} />
      <Route exact path="/extra-screen/:page/:from/:id" component={NewVisitorType} />
      <Route path="*" exact={true} component={Error} />
      </Switch>
      </BrowserRouter>

      
    </div>
  );
  
  export default BaseRouter;
