import React, { useState, useEffect } from 'react';
import { GetLocationUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import './SelectLocation.css'
import Logo from "../Logo/logo";
import Header from "../Header/header";
import { t } from '../../Translation/Translation';

const SelectLocation = props => {
    const history = useHistory();
    const [dataList, setdataList] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [errormessage, setErrormessage] = useState('');

    /**
     * fetch all location from backend and show in the drop down
     */
    useEffect(() => {
        let location = []
        APICALL.service(GetLocationUrl, 'GET')
            .then((result) => {
                if (result) {
                    Object.keys(result).map((value, i) => {
                        return location[value] = result[value];
                    })
                    setdataList(location);

                }
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    /**
     * select the location on onchange from dropdown
     * @param {*} data selected location
     */
    const handleLocationChange = (data) => {
        setErrormessage('')
        setSelectedLocation(data)
    }

    /**
     * save the selected location to the local storage
     */
    const saveLocation = () => {
        // console.log(selectedLocation['default_lang']['value']);
        if (selectedLocation !== undefined && selectedLocation !== '') {
            localStorage.setItem("location", JSON.stringify(selectedLocation));
            localStorage.setItem('activeLanguage', selectedLocation['default_lang']['value']);

            history.push({
                pathname: "/"

            });
        } else {
            setErrormessage('Please select location')
        }
    }

    return (
        <div>
            <Logo />
            <Header heading={t('Select Location')} />
            <div className="customscroll col-md-5 col-lg-5 col-sm-5 m-auto pt-5">
                {dataList !== undefined &&
                    <Select
                        className="loc-select"
                        multi={false}
                        options={dataList}
                        onChange={(e) => handleLocationChange(e)}
                        value={selectedLocation}
                        showNewOptionAtTop={false}
                    // isClearable={true}
                    />
                }
                <div className="col-md-12 col-lg-12 col-sm-12 mt-4">
                    <button className="bg-danger loc_btn " onClick={saveLocation}>{t('Save')}</button>
                </div>
            </div>
            <div>
                <p className="err_msg">
                    {errormessage}
                </p>
            </div>
        </div>
    );
};


export default SelectLocation;
