import React, { useState, useEffect } from 'react';
import { SafetyRulesUrl } from "../../Services/ApiEndPoints";
import { Screens } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import './SafetyRules.css';
import PDFReader from "../../Utilities/PdfReader/PdfReader";
import VideoPlayer from "../../Utilities/VideoPlayer/VideoPlayer";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import Header from "../Header/header";
import Logo from "../Logo/logo";
import GetRedirectionUrl from "../../Utilities/GetRedirectionUrl"
import setTimeOutForScreens from "../../Utilities/SetTimeOutforScreen"
import { t } from '../../Translation/Translation';

const SafetyRules = props => {
  console.log("SafetyRules");
  const [screenUrl, setScreenUrl] = useState("")
  const [fileType, setFileType] = useState("")
  const[nextButton, setNextButton] = useState(t("Accept"));
  const[nextButtonStyle, setNextButtonStyle] = useState({});
  let params = useParams();
  const history = useHistory()
  var page = "common"
  var response = GetRedirectionUrl(page)
  // useEffect(() => {
  //   var timer = setTimeOutForScreens(response)
  //   if(timer !== ''){
  //     setNextButton(t("HOME"))
  //     setNextButtonStyle({
  //       'background': '#D20C14'
  //     })
     
  //   }
  //   return () => clearTimeout(timer);
  // }, []);

  /**
   * this function will fetch the safety rules pdf or image and render the same.
   */
  useEffect(() => {
    console.log(params);
    if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
      var location = JSON.parse(localStorage.getItem("location"))
      let lang = (localStorage.getItem('activeLanguage'))? (localStorage.getItem('activeLanguage')): 'en'
      let locationId = location['value']
      // let prev
      let data = {
        'locationId': locationId,
        'type_id': params.type_id,
        'language': lang,
        'screen_id' : params.screen_id
      }
      // console.log(data);return;

      APICALL.service(Screens, "POST", data)
        .then((result) => {
          setScreenUrl(result.DATA['screens'])
          var file_type = (APICALL.get_url_extension(result.DATA['screens']));
          //  alert(file_type)
          if (file_type === "pdf") {
            setFileType("pdf");
          } else if (file_type === "mp4" || file_type === "mpeg" || file_type === "avi") {
            setFileType("video");
          } else {
            setFileType("image");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      history.push({
        pathname: "/select-location"

      });
    }
  }, [params.id]);

  return (
    <div>
      
      <Logo />

      <div className="col-md-12 col-lg-12 col-sm-12 with_out">
      <Header heading={t('Safety rules')} />
        {fileType === "pdf" && (
          <PDFReader
            // pdf={screenUrl} 
            pdf={APICALL.proxy_url  + screenUrl}
          />
        )}

        {fileType === "video" && (
          <VideoPlayer
              videoUrl={screenUrl} // Replace with the actual prop name for your VideoPlayer component
          />
        )}

        {fileType === "image" && (
          <div className="col-8 p-0 pt-4 m-auto pb-4">
            <img src={screenUrl} width="100%" alt="safety rules" height="auto" />
          </div>
        )}
      </div>
      <div className="col-md-12 col-lg-12 col-sm-12 mb-3 mt-1 p-0 row m-0 ">
        <div className="col-lg-6 col-md-6 col-sm-6 text-left p-0">
        {response['back'] !== undefined && response['back'] !== null ?
            (<button className="accept_button mr-10 back-home-button"
              onClick={() => {
                history.push({
                  pathname: response['back'],
                });
              }}
            >
              {t('Home')}
            </button>) : null
          }
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6  p-0 text-right">
          {response.next !== undefined && response.next !== null ?
            (<button className="accept_button" style={nextButtonStyle}
              onClick={() => {
                history.push({
                  pathname: response.next,
                });
              }}
            >
              {nextButton}
            </button>) : null
          }
        </div>
      </div>
    </div>

  );
};

export default SafetyRules;