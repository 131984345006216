export const ENV_URL = process.env.REACT_APP_BACKEND_URL;

export const REGEX_URL = 'api/bwelcomev1/';
export const BASE_URL = ENV_URL + REGEX_URL;



export const WithoutAppUrl = BASE_URL + "withoutappointments";
export const DepartmentUrl = BASE_URL + "departments";
export const CheckAppointmentsAppUrl = BASE_URL + "appointments";

export const CheckTheFlow = BASE_URL + "check-flow";

export const WelcomeGuestUrl = BASE_URL + "welcomeguest";
// export const CheckAppointmentsAppUrl = BASE_URL + "appointments"; 
export const DeliveryUrl = BASE_URL + "deliverys";
export const ContractorUrl = BASE_URL + "contractors";

export const SafetyRulesUrl = BASE_URL + "safetyrules";
export const Screens = BASE_URL + "screens";
export const ScreensWithId = BASE_URL + "screens/";


// export const VisitorWaitingForHosts = BASE_URL + "visitorwaitingforhosts";
export const ThankYouScreen = BASE_URL + "thankyou";

export const GetLocationUrl = BASE_URL + "locations";
export const VisitorTypeUrl = BASE_URL + "visitortypes";

export const DataTranslations = BASE_URL + "translations";
export const evacuationreports = BASE_URL + "evacuationreports";

export const GetDeviceDetails = BASE_URL + "deviceids";
export const CheckoutVisitors = BASE_URL + "checkoutvisitors";
export const Deleteappointments = BASE_URL + "deleteappointments";
export const GetApiResults = BASE_URL + "gettokens";


// export const UpdateWithoutAppUrl = BASE_URL + "withoutappointments";

//follow the below example to add your url endpoints

// EXAMPLE : export const getUserDetails = BASE_URL + 'account/get-user-details';