import React from 'react';
import logo from "../../Static/images/logo_bwelcome.svg";
import "../SelectVisitor/SelectVisitor.css";
import { t } from '../../Translation/Translation';

const Logo = props => {

  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update
  var location = '';
  if (localStorage.getItem("location") && localStorage.getItem("location") !== null) {
    location = JSON.parse(localStorage.getItem("location"))
    location = location.label
  } 
  React.useEffect(() => {
    const timer = setInterval(() => { // Creates an interval which will update the current data every second
    // This will trigger a rerender every component that uses the useDate hook.
    setDate(new Date());
  },  1000);
  return () => {
    clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
  }
}, []);
  var hour = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
  var min = today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
  var dd = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
  // var mm = parseInt(today.getMonth()+1) < 10 ? '0' + parseInt(today.getMonth()+1) : parseInt(today.getMonth()+1)
  var yy = today.getFullYear() < 10 ? '0' + today.getFullYear() : today.getFullYear()
  // var mn = t(today.toLocaleString('default', { month: 'long' }))
  var mn = today.toLocaleString('default', { month: 'long' })

  var date = dd +' '+mn +' ' +yy
  var time = hour + ':' + min 
  return (

    <div className="header_main1 col-md-12 col-lg-12 row m-0 pt-3 pb-4 p-0">
      <div className="logo_sec header col-md-1 col-lg-3 p-0">
        <a href="/"> <img className="logo_sec" alt="logo" src={logo}></img></a>
        {/* <span className="pl-3 label_own2" >{time}</span> */}

      </div>
      <div className="time_sec header col-md-11 col-lg-9 p-0 label_own2">
       <span className="header_location">{location} {time}</span><br/><span>{date}</span>

      </div>
     
    </div>
  );
};

export default Logo;